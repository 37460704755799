<template>
  <div class="overflow-auto p-10">
    <h1 class="text-5xl mb-4 text-prasset-green-500">
      Components
    </h1>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">Colors</h2>

    <div class="grid grid-cols-4 gap-4 my-4">
      <div class="flex flex-row items-center">
        <div class="bg-prasset-red-500 rounded-full w-8 h-8 block" />
        <p class="text-black text-base ml-3">prasset-red-500</p>
      </div>

      <div class="flex flex-row items-center">
        <div class="bg-prasset-green-500 rounded-full w-8 h-8 block" />
        <p class="text-black text-base ml-3">prasset-green-500</p>
      </div>

      <div class="flex flex-row items-center">
        <div class="bg-prasset-gray-800 rounded-full w-8 h-8 block" />
        <p class="text-black text-base ml-3">prasset-gray-800</p>
      </div>

      <div class="flex flex-row items-center">
        <div class="bg-prasset-gray-900 rounded-full w-8 h-8 block" />
        <p class="text-black text-base ml-3">prasset-gray-900</p>
      </div>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">Buttons</h2>
    <div class="grid grid-cols-4 gap-4 my-4">
      <div class="bg-checker">
        <h3 class="font-medium mb-2">Base</h3>
        <a class="button" href="#"></a>
        <a class="button" href="#"><i class="icon-delete" /></a>
        <a class="button" href="#">save</a>
        <a class="button" href="#">yes, delete</a>
        <button class="button" href="#">yes, delete all my stuff</button>
        <button class="button" href="#"><i class="icon-delete" /></button>
        <button class="button" href="#"><i class="icon-delete mr-2" />verwijderen</button>
        <button class="button button--clear" href="#"><i class="icon-delete" /></button>
      </div>
      <div class="bg-checker">
        <h3 class="font-medium mb-2">Opague</h3>
        <a class="button button--opague" href="#"></a>
        <a class="button button--opague" href="#"><i class="icon-delete" /></a>
        <a class="button button--opague" href="#">save</a>
        <a class="button button--opague" href="#">yes, delete</a>
        <button class="button button--opague" href="#">yes, delete all my stuff</button>
        <button class="button button--opague" href="#"><i class="icon-delete" /></button>
        <button class="button button--opague" href="#"><i class="icon-delete mr-2" />verwijderen</button>
        <a class="button button--opague button--clamp" href="#"></a>
        <a class="button button--opague button--clamp" href="#"><i class="icon-filter" /></a>
        <a class="button button--opague button--clamp" href="#">cancel</a>
      </div>
      <div class="bg-checker">
        <h3 class="font-medium mb-2">Outlined</h3>
        <a class="button button--outlined" href="#"></a>
        <a class="button button--outlined" href="#"><i class="icon-delete" /></a>
        <a class="button button--outlined" href="#">cancel</a>
        <a class="button button--outlined" href="#">yes, delete</a>
        <button class="button button--outlined" href="#">yes, delete all my stuff</button>
        <button class="button button--outlined" href="#"><i class="icon-delete" /></button>
        <button class="button button--outlined" href="#"><i class="icon-delete mr-2" />verwijderen</button>
        <a class="button button--outlined button--clamp" href="#"></a>
        <a class="button button--outlined button--clamp" href="#"><i class="icon-filter" /></a>
        <a class="button button--outlined button--clamp" href="#">cancel</a>
      </div>
      <div class="bg-checker">
        <h3 class="font-medium mb-2">Mixed</h3>
        <div class="mb-4">
          <a class="button" href="#">cancel</a>
          <a class="button button--opague" href="#">cancel</a>
          <a class="button button--outlined" href="#">cancel</a>
        </div>
        <a class="button button--small" href="#"></a>
        <a class="button button--small" href="#"><i class="icon-delete" /></a>
        <a class="button button--small" href="#">cancel</a>
        <a class="button button--small button--opague" href="#">cancel</a>
        <a class="button button--small button--outlined" href="#">cancel</a>
        <a class="button button--small button--outlined button--clamp" href="#"></a>
        <a class="button button--small button--outlined button--clamp" href="#"><i class="icon-filter" /></a>
        <a class="button button--small button--outlined button--clamp" href="#">cancel</a>
      </div>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">CheckField</h2>
    <div class="grid grid-cols-4 gap-4 my-4">
      <div>
        <CheckField v-model="checkedMultiValue" value="2" :disabled="true">disabled, multi, value 2</CheckField>
        <CheckField v-model="checkedMultiValue" />
        <CheckField v-model="checkedMultiValue" value="2">test</CheckField>

        <pre class="text-xs bg-gray-300 my-2 text-prasset-gray-800 rounded p-2">{{ checkedMultiValue }}</pre>

        <CheckField v-model="checkedSingleValue" :checked="true">single, bool value</CheckField>
        <pre class="text-xs bg-gray-300 my-2 text-prasset-gray-800 rounded p-2">{{ checkedSingleValue }}</pre>
      </div>
      <div>
        <div class="flex">
          <CheckField />
          <CheckField />
          <CheckField />
        </div>
        <div class="flex">
          <CheckField class="m-1" />
          <CheckField class="m-1" />
          <CheckField class="m-1" />
        </div>
      </div>
      <div>
        <CheckField>Label for checkfield Label for checkfield Label for checkfield Label for checkfield</CheckField>
      </div>
      <div>
        <CheckField>Label for checkfield</CheckField>
      </div>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">RadioField</h2>
    <div class="grid grid-cols-4 gap-4 my-4">
      <div>
        <RadioField />
        <RadioField value="1" name="group">Label for radiofield 1</RadioField>
        <RadioField value="2" name="group">Label for radiofield 2</RadioField>
      </div>
      <div>
        <div class="flex">
          <RadioField />
          <RadioField />
          <RadioField />
        </div>
      </div>
      <div>
        <RadioField value="2" name="group">Label for radiofield 2 Label for radiofield 2 Label for radiofield 2</RadioField>
      </div>
      <div>
        <RadioField value="2" name="group">Label for radiofield 2</RadioField>
      </div>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">IconPicker</h2>
    <div class="grid grid-cols-4 gap-4 my-4 z-10">
      <div>
        <IconPicker v-model="selectedIcon" />
        <pre class="text-xs bg-gray-300 my-2 text-prasset-gray-800 rounded p-2">{{ selectedIcon }}</pre>
      </div>
      <div>
        <IconPicker :error="['validation.required']" />
      </div>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">SelectField</h2>
    <div class="grid grid-cols-4 gap-4 my-4">
      <div>
        <SelectField :options="{key1: 'Value 1', key2: 'Value 2'}" :optional="false" />
      </div>
      <div>
        <SelectField endpoint="/projects" />
      </div>
      <div>
        <SelectField :options="{key1: 'Value 1', key2: 'Value 2'}" />
      </div>
      <div>
        <SelectField :options="{key1: 'Value 1', key2: 'Value 2'}" :error="['validation.required']" />
      </div>
    </div>

    <h2 id="multiselect" class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">MultiSelectField</h2>
    <div class="grid grid-cols-4 gap-4 my-4 z-30">
      <div>
        <MultiSelectField v-model="multiselectValue1" :options="{key1: 'Value 1', key2: 'Value 2'}" :disabled="true" />
      </div>
      <div>
        <MultiSelectField v-model="multiselectValue" endpoint="/projects" placeholder="zoek of selecteer" />
        <pre class="text-xs bg-gray-300 my-2 text-prasset-gray-800 rounded p-2">{{ multiselectValue }}</pre>
      </div>
      <div>
        <MultiSelectField v-model="multiselectValue1" :options="{key1: 'Value 1', key2: 'Value 2'}" :readonly="true" />
        <pre class="text-xs bg-gray-300 my-2 text-prasset-gray-800 rounded p-2">{{ multiselectValue1 }}</pre>
      </div>
      <div>
        <MultiSelectField v-model="multiselectValue1" :options="{key1: 'Value 1', key2: 'Value 2'}" :error="['validation.required']" />
      </div>
    </div>

    <h2 id="singleselect" class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">SingleSelectField</h2>
    <div class="grid grid-cols-4 gap-4 my-4 z-30">
      <div>
        <SingleSelectField v-model="singleselectValue1" :options="{key1: 'Value 1', key2: 'Value 2'}" :disabled="true" />
      </div>
      <div>
        <SingleSelectField v-model="singleselectValue" endpoint="/projects" placeholder="zoek of selecteer" />
        <pre class="text-xs bg-gray-300 my-2 text-prasset-gray-800 rounded p-2">{{ singleselectValue }}</pre>
      </div>
      <div>
        <SingleSelectField v-model="singleselectValue1" :options="{key1: 'Value 1', key2: 'Value 2'}" :readonly="true" />
        <pre class="text-xs bg-gray-300 my-2 text-prasset-gray-800 rounded p-2">{{ singleselectValue1 }}</pre>
      </div>
      <div>
        <SingleSelectField v-model="singleselectValue" :options="{key1: 'Value 1', key2: 'Value 2'}" :error="['validation.required']" />
      </div>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">TextField</h2>
    <div class="grid grid-cols-4 gap-4 my-4">
      <div>
        <TextField placeholder="placeholder" />
      </div>
      <div>
        <TextField value="hallo" />
      </div>
      <div>
        <TextField v-model="textFieldContent" />
        <pre class="text-xs bg-gray-300 my-2 text-prasset-gray-800 rounded p-2">{{ textFieldContent }}</pre>
      </div>
      <div>
        <TextField :value="textFieldContent" :required="true" :error="['validation.required']" />
      </div>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">LinkField</h2>
    <div class="grid grid-cols-4 gap-4 my-4">
      <div>
        <LinkField name="linkje" v-model="linkfield" />
        <pre class="text-xs bg-gray-300 my-2 text-prasset-gray-800 rounded p-2">{{ linkfield }}</pre>
      </div>
      <div>
        <LinkField name="linkje" :error="['validation.required']" />
      </div>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">DateField</h2>
    <div class="grid grid-cols-4 gap-4 my-4">
      <div>
        <DateField />
      </div>
      <div>
        <DateField />
      </div>
      <div>
        <DateField value="2020-03-10" />
      </div>
      <div>
        <DateField :error="['validation.required']" />
      </div>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">DateRangeField</h2>
    <div class="grid grid-cols-4 gap-4 my-4">
      <div>
        <DateRangeField />
      </div>
      <div>
        <DateRangeField />
      </div>
      <div>
        <DateRangeField />
      </div>
      <div>
        <DateRangeField :error="['validation.required']" />
      </div>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">PasswordField</h2>
    <div class="grid grid-cols-4 gap-4 my-4">
      <div>
        <PasswordField />
      </div>
      <div>
        <PasswordField />
      </div>
      <div>
        <PasswordField />
      </div>
      <div>
        <PasswordField :error="['validation.required']" />
      </div>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">ToggleField</h2>
    <div class="grid grid-cols-4 gap-4 my-4">
      <div>
        <ToggleField />
        <ToggleField />
      </div>
      <div>
        <ToggleField placeholder="placeholder" />
      </div>
      <div>
        <ToggleField id="togglefield" />
        <label for="togglefield">label</label>
      </div>
      <div>
        <ToggleField placeholder="placeholder" :error="['validation.required']" />
      </div>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">FilesField</h2>
    <FilesField />

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">HtmlEditorField</h2>
    <HtmlEditorField />

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">MarkdownField</h2>
    <div class="grid grid-cols-2 gap-6">
      <div><MarkdownField name="md" v-model="markdown" /></div>
      <pre class="text-xs bg-gray-300 my-2 text-prasset-gray-800 rounded p-2">{{ markdown }}</pre>
    </div>

    <h2 class="text-2xl my-4 font-medium text-prasset-green-500 border-b border-gray-300">FieldsGenerator</h2>
    <div class="grid grid-cols-2 gap-6 mb-32">
      <FieldsGenerator :fields="formfields" :data="formdata" />
      <pre class="text-xs bg-gray-300 my-2 text-prasset-gray-800 rounded p-2">{{ formdata }}</pre>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive } from '@vue/composition-api';
import CheckField from '@/components/field/Check';
import DateField from '@/components/field/Date';
import DateRangeField from '@/components/field/DateRange';
import FieldsGenerator from '@/modules/core/views/components/FieldsGenerator';
import FilesField from '@/components/field/Files';
import HtmlEditorField from '@/components/field/HtmlEditor';
import IconPicker from '@/components/field/IconPicker';
import LinkField from '@/components/field/Link';
import MarkdownField from '@/components/field/Markdown';
import MultiSelectField from '@/components/field/MultiSelect';
import PasswordField from '@/components/field/Password';
import RadioField from '@/components/field/Radio';
import SelectField from '@/components/field/Select';
import SingleSelectField from '@/components/field/SingleSelect';
import TextField from '@/components/field/Text';
import ToggleField from '@/components/field/Toggle';

export default {
  components: {
    CheckField,
    DateField,
    DateRangeField,
    FieldsGenerator,
    FilesField,
    HtmlEditorField,
    IconPicker,
    LinkField,
    MarkdownField,
    MultiSelectField,
    PasswordField,
    RadioField,
    SelectField,
    TextField,
    ToggleField,
    SingleSelectField,
  },

  setup() {
    const state = reactive({
      multiselectValue: ['unknown-1912-458b-88a2-ee5a27a598b1'],
      multiselectValue1: ['key1','unknown'],
      checkedMultiValue: [],
      checkedSingleValue: null,
      singleselectValue: null,
      singleselectValue1: '1912-458b-88a2-ee5a27a598b1',
      textFieldContent: 'test',
      selectedIcon: 'ri-ancient-gate-fill',
      markdown: '# h1 Heading\n1. Lorem ipsum dolor sit amet\n2. Consectetur adipiscing elit\n3. Integer molestie lorem at massa\n## h2 Heading\n### **h3** Heading\n#### h4 Heading\n##### h5 Heading\n###### h6 Heading',
      linkfield: null,
      formdata: {
        gender: 'male',
        description: 'holy moly',
        checkfield: true,
        htmlfield: 'test',
        preferences: [
          {
            name: 'regel 1',
            status: 'IS_SUBMITTED',
          },
          {
            name: 'regel 2',
            status: 'IS_DECLINED',
          },
        ],
      },
      formfields: [
        {
          type: 'repeater',
          name: 'preferences',
          label: 'Voorkeuren',
          colspan: 3,
          fields: [
            {
              label: 'Name',
              name: 'name',
              type: 'text',
              colspan: 3,
            },
            {
              label: 'Status',
              name: 'status',
              type: 'select',
              options: {
                IS_SUBMITTED: 'IS_SUBMITTED',
                IS_APPROVED: 'IS_APPROVED',
                IS_DECLINED: 'IS_DECLINED',
                IS_ASSIGNED: 'IS_ASSIGNED',
              },
              colspan: 3,
            },
          ],
        },
        {
          type: 'repeater',
          name: 'settings',
          label: 'Instellingen',
          colspan: 3,
          fields: [
            {
              label: 'Firstname',
              name: 'name',
              type: 'text',
            },
            {
              label: 'Omschrijving',
              name: 'description',
              type: 'textarea',
              colspan: 6,
            },
            {
              label: 'checkfield',
              name: 'checkfield',
              type: 'check',
            },
            {
              label: 'radiofield',
              name: 'radiofield',
              type: 'radio',
              options: {
                test: 'hallow',
                test2: 'hallow2',
              },
            },
            {
              label: 'togglefield',
              name: 'togglefield',
              type: 'toggle',
            },
            {
              label: 'datefield',
              name: 'datefield',
              type: 'date',
            },
            {
              label: 'htmlfield',
              name: 'htmlfield',
              type: 'html',
            },
            {
              label: 'multiselectfield',
              name: 'multiselectfield',
              type: 'multiselect',
              options: {
                a: 'A',
                b: 'B',
              },
            },
            {
              label: 'multiselectfield',
              name: 'projects',
              type: 'multiselect',
              endpoint: '/projects',
              placeholder: 'zoek of selecteer',
            },
            {
              label: 'iconpicker',
              name: 'icon',
              type: 'iconpicker',
            },
            {
              label: 'Link',
              name: 'linkje',
              type: 'link',
            },
            // {
            //   label: 'iconpicker (multiple)',
            //   name: 'icons',
            //   type: 'iconpicker',
            //   multiple: true,
            // },
          ],
        },
        {
          type: 'section',
          title: 'Persoonsgegevens',
          content: 'hallo',
        },
        {
          label: 'Link',
          name: 'the_perfect_link',
          type: 'link',
        },
        {
          label: 'Geslacht',
          name: 'gender',
          map: 'gender',
          type: 'select',
          options: {
            male: 'Man',
            female: 'Vrouw',
          },
          colspan: 2,
        },
        {
          label: 'Naam',
          name: 'name',
          type: 'text',
          colspan: 2,
        },
        {
          label: 'Naam (linked)',
          name: 'name',
          type: 'text',
          colspan: 2,
        },
        {
          label: 'Omschrijving',
          name: 'description',
          type: 'textarea',
          colspan: 2,
        },
        {
          label: 'checkfield',
          name: 'checkfield',
          type: 'check',
          colspan: 2,
        },
        {
          label: 'checkfield 2',
          name: 'checkfield2',
          type: 'check',
          colspan: 2,
        },
        {
          label: 'radiofield',
          name: 'radiofield',
          type: 'radio',
          colspan: 2,
        },
        {
          label: 'daterangefield',
          name: 'daterangefield',
          type: 'date-range',
        },
        {
          label: 'htmlfield',
          name: 'htmlfield',
          type: 'html',
        },
        {
          label: 'multiselectfield',
          name: 'multiselectfield',
          type: 'multiselect',
          options: {
            a: 'A',
            b: 'B',
          },
        },
        {
          label: 'iconpicker',
          name: 'iconpicker',
          type: 'iconpicker',
        },
        // {
        //   label: 'filesfield',
        //   name: 'filesfield',
        //   type: 'files',
        // },
      ],
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
  :root {
    --checkerColor: #eee;
  }
  .bg-checker {
    background-image: linear-gradient(45deg, var(--checkerColor) 25%, transparent 25%),
                      linear-gradient(-45deg, var(--checkerColor) 25%, transparent 25%),
                      linear-gradient(45deg, transparent 75%, var(--checkerColor) 75%),
                      linear-gradient(-45deg, transparent 75%, var(--checkerColor) 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  }
</style>
