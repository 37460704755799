<template>
  <div class="grid grid-cols-6 gap-6 mb-4">
    <FieldsGeneratorField
      v-for="(field, idx) in fields"
      :key="idx"
      :errors="errors"
      :field="field"
      :data="data"
      :parent="parent"
      :entryId="entryId"
      :entryType="entryType"
      :fileCollection="fileCollection"
      :style="{
        'z-index': fields.length - idx,
        'display': displayIf(field) ? 'flex': 'none',
      }"
      :class="`col-span-${field.colSpan || field.colspan || 6} flex flex-col`"
    />
  </div>
</template>

<script>
/*
tailwind-purge:
col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6
*/

import useFieldConditions from '@/compositions/useFieldConditions';

export default {
  name: 'FieldsGeneratorFields',

  // components: {
  //   FieldsGeneratorField, // registered in main.js
  // },

  props: {
    fields: {
      type: Array,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    errors: {
      type: [Array, Object],
      default: () => ({}),
    },

    parent: {
      type: Object,
      default: null,
    },

    entryType: {
      type: String,
      default: null,
    },

    entryId: {
      type: String,
      default: null,
    },

    fileCollection: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const { fieldConditionsFor } = useFieldConditions();

    return {
      displayIf: field => fieldConditionsFor('displayIf', props.data, field),
    };
  },
};
</script>
