<template>
  <div class="form-builder">
    <FieldsGeneratorFields
      :fields="fields"
      :data="data"
      :errors="errors"
      :entryId="entryId"
      :entryType="entryType"
      :fileCollection="fileCollection"
    />
  </div>
</template>

<script>
import FieldsGeneratorFields from '@/modules/core/views/components/FieldsGeneratorFields';

export default {
  name: 'FieldsGenerator',

  components: {
    FieldsGeneratorFields,
  },

  props: {
    fields: {
      type: Array,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    errors: {
      type: [Array, Object],
      default: () => ({}),
    },

    entryType: {
      type: String,
      default: null,
    },

    entryId: {
      type: String,
      default: null,
    },

    fileCollection: {
      type: Array,
      default: () => [],
    },
  },

  // watch: {
  //   data: {
  //     deep: true,
  //     handler(value) {
  //       this.$emit('change', value);
  //     },
  //   },
  // },
};
</script>
